export const getAccountName = (state) => {
  const user = state.login.user;
  return user && (user.name || user.eosAccount);
};
export const getAccountUser = (state) => state.login.user;
export const getAccountBalance = (state) => {
  const balances = state.wallet.balances;
  const currency = state?.login?.currency?.name;
  if (currency && balances?.data && balances.data[currency]) {
    return balances.data[currency];
  }
  return state?.login?.balance;
};
export const getAccountCurrency = (state) => state.login.currency.name;
export const getAccountCurrencyLoading = (state) => state.login.currency.isLoading;
export const getAccountCurrencyMetadata = (state) => state.login.currency;
export const getAccountIsVip = (state) => {
  const user = getAccountUser(state);
  if (!user) return false;
  return !!user.vip;
};
export const getIsScatterUser = (state) => {
  const user = getAccountUser(state);
  if (!user) return false;
  return user.provider === "scatter";
};
export const getUserProvider = (state) => {
  const user = getAccountUser(state);
  if (!user) return false;
  return user.provider;
};
export const getEosAccount = (state) => {
  const user = state.login && state.login.user;
  return user && user.eosAccount;
};
export const isDepositOptionsEnabledSelector = (state) => {
  const user = state.login && state.login.user;
  return user?.depositOptionsEnabled ?? false;
};
export const getLoginError = (state) => state.login.error;
export const getLoginProgress = (state) => state.login.progress;
export const getLoginInit = (state) => state.login.init;
export const getLoginToken = (state) => state.login.token;
export const getLoginPopupShown = (state) => state.login.popupShown;
export const getSignupPopupShown = (state) => state.login.signupPopupShown;
export const getLoginSigningIn = (state) => state.login.signingIn;
export const getLoginIsSignInEverFinished = (state) => state.login.isSignInEverFinished;
