import { notificationType as notificationItemType } from "@sbetdev2/dbmodel/enums";
import * as bonusApi from "api/bonusApi";
import { BonusGetAndAcceptStatuses } from "reducers/bonus/bonusGetAndAccept.ts";

import { storeSelectedBonus } from "components/bonus/bonus.utils";

import { localStorageUtils } from "utils/browserUtils";

import {
  BONUS_ACCEPT_REFUSE_ERROR,
  BONUS_ACCEPT_REFUSE_LOADED,
  BONUS_ACCEPT_REFUSE_LOADING,
  BONUS_ACTIVE_ERROR,
  BONUS_ACTIVE_LOADED,
  BONUS_ACTIVE_LOADING,
  BONUS_CANCEL_ERROR,
  BONUS_CANCEL_LOADED,
  BONUS_CANCEL_LOADING,
  BONUS_ERROR_CLEAR,
  BONUS_GET_AND_ACCEPT_ERROR,
  BONUS_GET_AND_ACCEPT_LOADING,
  BONUS_GET_AND_ACCEPT_SUCCESS,
  BONUS_LIST_ERROR,
  BONUS_LIST_LOADED,
  BONUS_LIST_LOADING,
  BONUS_PACKS_ERROR,
  BONUS_PACKS_LOADED,
  BONUS_PACKS_LOADING,
  BONUS_REGISTER_ERROR,
  BONUS_REGISTER_LOADED,
  BONUS_REGISTER_LOADING,
  BONUS_REGISTER_REFRESH,
  NOTIFICATIONS_RECENT_LOADED,
} from "./actionTypes";

export const registerBonusCode = (code) => async (dispatch) => {
  try {
    dispatch({ type: BONUS_REGISTER_LOADING });
    const { activeCode, bonusPacks } = await bonusApi.register(code);

    if (!activeCode || !bonusPacks?.length)
      throw new Error("The code appears to be incorrect");

    const pack = bonusPacks?.find((b) => b.code === activeCode);

    storeSelectedBonus({ activeCode, bonusCode: code, activeBonusId: pack?.id });

    const bonusPacksWithoutDupes = bonusPacks.filter(
      (p, idx) => bonusPacks.findIndex((pInfo) => pInfo.id === p.id) === idx
    );

    dispatch({ type: BONUS_REGISTER_LOADED, payload: { activeCode } });
    dispatch({
      type: BONUS_PACKS_LOADED,
      payload: { bonusPacks: bonusPacksWithoutDupes },
    });

    const isCodeHasBonuses = bonusPacks.find((item) => !item?.public);
    if (isCodeHasBonuses) {
      dispatch({
        type: "NOTIFICATIONS_RECENT_LOADED",
        payload: {
          items: [{ _id: Date.now(), type: 10, count: 1, withoutRedirect: true }],
        },
      });
    } else {
      throw new Error("Bonus code is not available");
    }
  } catch (e) {
    dispatch({
      type: BONUS_REGISTER_ERROR,
      payload: { error: e?.message },
    });
    dispatch({
      type: BONUS_PACKS_ERROR,
      payload: { error: "Cannot load bonus packs" },
    });
  }
};

export const loadBonusPacks = (code, noRegister) => async (dispatch) => {
  try {
    const activeCode = localStorageUtils.getItem("activeCode");
    const paramPack = localStorageUtils.getItem("activeBonusId");
    if (!activeCode && code && !noRegister) {
      return dispatch(registerBonusCode(code));
    }

    dispatch({ type: BONUS_PACKS_LOADING });
    const { bonusPacks } = await bonusApi.getBonusPacks(activeCode, paramPack);
    dispatch({ type: BONUS_PACKS_LOADED, payload: { bonusPacks } });
  } catch (e) {
    dispatch({ type: BONUS_PACKS_ERROR, payload: { error: "Cannot load bonus packs" } });
  }
};

export const loadActiveBonus = () => async (dispatch) => {
  try {
    dispatch({ type: BONUS_ACTIVE_LOADING });

    const [pendingDeposit, wageringBonus] = await Promise.all([
      bonusApi.getPendingDepositBonus(),
      bonusApi.getWageringBonus(),
    ]);

    if (pendingDeposit.code === 401 && window.localStorage.getItem("auth")) {
      window.localStorage.removeItem("auth");

      dispatch({
        type: NOTIFICATIONS_RECENT_LOADED,
        payload: {
          items: [
            {
              _id: "system_message",
              type: notificationItemType.sessionExpired,
            },
          ],
        },
      });
      return;
    }

    dispatch({
      type: BONUS_ACTIVE_LOADED,
      payload: {
        bonus:
          (wageringBonus && wageringBonus.bonus) ||
          (pendingDeposit && pendingDeposit.bonus),
      },
    });
  } catch (e) {
    dispatch({
      type: BONUS_ACTIVE_ERROR,
      payload: { error: "Cannot load active bonus" },
    });
  }
};

export const loadBonuses = () => async (dispatch) => {
  try {
    dispatch({ type: BONUS_LIST_LOADING });
    const { bonuses } = await bonusApi.getBonuses();

    bonuses.sort(
      (a, b) =>
        new Date(b.updatedAt ?? 0).getTime() - new Date(a.updatedAt ?? 0).getTime()
    );

    dispatch({ type: BONUS_LIST_LOADED, payload: { bonuses } });
  } catch (e) {
    dispatch({ type: BONUS_LIST_ERROR, payload: { error: "Cannot load bonuses list" } });
  }
};

export const clearBonusError = () => async (dispatch) => {
  dispatch({ type: BONUS_ERROR_CLEAR });
};

export const cancelBonus = (bonusId) => async (dispatch) => {
  try {
    dispatch({ type: BONUS_CANCEL_LOADING });
    await bonusApi.cancelBonus(bonusId);
    dispatch({ type: BONUS_CANCEL_LOADED });
    dispatch(loadBonuses());
  } catch (e) {
    dispatch({
      type: BONUS_CANCEL_ERROR,
      payload: { error: "Cannot cancel bonus at the moment" },
    });
  }
};

export const refreshBonus = () => async (dispatch) => {
  dispatch({ type: BONUS_REGISTER_REFRESH });
};

export const acceptBonus = (bonusId) => async (dispatch) => {
  try {
    dispatch({ type: BONUS_ACCEPT_REFUSE_LOADING });
    await bonusApi.acceptBonus(bonusId);
    dispatch({ type: BONUS_ACCEPT_REFUSE_LOADED });
    dispatch(loadBonuses());
  } catch (e) {
    dispatch({
      type: BONUS_ACCEPT_REFUSE_ERROR,
      payload: { error: "Cannot accept bonus at the moment" },
    });
  }
};

export const getBonusAndAccept = (bonusTemplateId) => async (dispatch) => {
  dispatch({ type: BONUS_GET_AND_ACCEPT_LOADING });
  try {
    const { status } = await bonusApi.getBonusAndAccept(bonusTemplateId);
    dispatch({ type: BONUS_GET_AND_ACCEPT_SUCCESS, payload: { status } });
  } catch (e) {
    dispatch({
      type: BONUS_GET_AND_ACCEPT_ERROR,
      payload: { status: BonusGetAndAcceptStatuses.ERROR },
    });
  }
};
