/** Page **/
export const PAGE = "PAGE";
export const PAGE_SET_PARAMS = "PAGE_SET_PARAMS";
export const PAGE_SET_IDS = "PAGE_SET_IDS";
export const PAGE_RESET = "PAGE_RESET";
/** Country **/
export const COUNTRY = "COUNTRY";
export const SET_IS_RESTRICTED = "SET_IS_RESTRICTED";
export const SET_IS_MIRROR_POPUP_AVAILABLE = "SET_IS_MIRROR_POPUP_AVAILABLE";
/** Carousel**/
export const ACTION_TYPE_SLIDER = "SLIDER";
/** Sports **/
export const SPORT_LOADING = "SPORT_LOADING";
export const SPORT_LOADED = "SPORT_LOADED";
export const SPORT_LOAD_ERROR = "SPORT_LOAD_ERROR";
export const SPORT_FILTER_SET = "SPORT_FILTER_SET";
export const SPORT_DATE_FILTER_SET = "SPORT_DATE_FILTER_SET";
export const SPORT_SET_LIVE_STREAM_FILTER = "SPORT_SET_LIVE_STREAM_FILTER";
export const SPORT_SET_LIVE_SPORTS_COUNT = "SPORT_SET_LIVE_SPORTS_COUNT";
export const SPORT_MONITOR_START = "SPORT_MONITOR_START";
export const SPORT_MONITOR_STOP = "SPORT_MONITOR_STOP";
export const LIVE_SPORT_MONITOR_START = "LIVE_SPORT_MONITOR_START";
export const LIVE_SPORT_MONITOR_STOP = "LIVE_SPORT_MONITOR_STOP";
export const SPORT_SET_ACTIVE = "SPORT_SET_ACTIVE";
/** Events **/
export const EVENTS_LOADED = "EVENTS_LOADED";
export const EVENTS_LOADING = "EVENTS_LOADING";
export const EVENTS_LOAD_ERROR = "EVENTS_LOAD_ERROR";
export const EVENT_MONITOR_START = "EVENT_MONITOR_START";
export const EVENT_MONITOR_STOP = "EVENT_MONITOR_STOP";
export const EVENT_LOADING = "EVENT_LOADING";
export const EVENT_LOADED = "EVENT_LOADED";
export const EVENT_LOAD_ERROR = "EVENT_LOAD_ERROR";
export const CLEAR_EVENT = "CLEAR_EVENT";

/** Event Breadcrumbs */
export const EVENT_BREADCRUMBS_LOADED = "EVENT_BREADCRUMBS_LOADED";
export const EVENT_BREADCRUMBS_RESET = "EVENT_BREADCRUMBS_RESET";
/** Login **/
export const LOGIN_PROGRESS = "LOGIN_PROGRESS";
export const LOGIN_INIT = "LOGIN_INIT";
export const LOGIN_COMPLETE = "LOGIN_COMPLETE";
export const LOGIN_BALANCE = "LOGIN_BALANCE";
export const LOGIN_RESET = "LOGIN_RESET";
export const LOGOUT_COMPLETE = "LOGOUT_COMPLETE";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SHOW_POPUP = "LOGIN_SHOW_POPUP";
export const LOGIN_HIDE_POPUP = "LOGIN_HIDE_POPUP";
export const SIGNUP_SHOW_POPUP = "SIGNUP_SHOW_POPUP";
export const SIGNUP_HIDE_POPUP = "SIGNUP_HIDE_POPUP";
export const LOGIN_SET_SIGNING_IN = "LOGIN_SET_SIGNING_IN";
export const LOGIN_UPDATE_USER = "LOGIN_UPDATE_USER";
export const LOGIN_SET_EOS_ACCOUNT = "LOGIN_SET_EOS_ACCOUNT";
export const LOGIN_DEPOSIT_OPTIONS_DISABLE = "LOGIN_DEPOSIT_OPTIONS_DISABLE";
/** Dividends **/
export const DIVIDENDS_LOADING = "DIVIDENDS_LOADING";
export const DIVIDENDS_LOADED = "DIVIDENDS_LOADED";
export const DIVIDENDS_ERROR = "DIVIDENDS_ERROR";
export const DIVIDENDS_PROGRESS = "DIVIDENDS_PROGRESS";
export const DIVIDENDS_SET_TOKEN_AMOUNT = "DIVIDENDS_SET_TOKEN_AMOUNT";
export const DIVIDENDS_STAKE_PROGRESS = "DIVIDENDS_STAKE_PROGRESS";
export const DIVIDENDS_STAKE_ERROR = "DIVIDENDS_STAKE_ERROR";
export const DIVIDENDS_STAKE_RESET = "DIVIDENDS_STAKE_RESET";
export const DIVIDENDS_CLAIM_PROGRESS = "DIVIDENDS_CLAIM_PROGRESS";
export const DIVIDENDS_CLAIM_ERROR = "DIVIDENDS_CLAIM_ERROR";
/** Currency **/
export const CURRENCY_CHANGED = "CURRENCY_CHANGED";
export const CURRENCY_CHANGING = "CURRENCY_CHANGING";
export const CURRENCY_CHANGE_ERROR = "CURRENCY_CHANGE_ERROR";
/** Side Menu **/
export const SIDE_MENU_TOGGLE = "SIDE_MENU_TOGGLE";
/** BTC **/
export const BTC_ADDRESS_LOADING = "BTC_ADDRESS_LOADING";
export const BTC_ADDRESS_LOADED = "BTC_ADDRESS_LOADED";
export const BTC_ADDRESS_LOAD_ERROR = "BTC_ADDRESS_LOAD_ERROR";
export const BTC_WITHDRAWAL_STARTED = "BTC_WITHDRAWAL_STARTED";
export const BTC_WITHDRAWAL_SUCCESS = "BTC_WITHDRAWAL_SUCCESS";
export const BTC_WITHDRAWAL_ERROR = "BTC_WITHDRAWAL_ERROR";
/** PTokens **/
export const PTOKEN_ADDRESS_LOADING = "PTOKEN_ADDRESS_LOADING";
export const PTOKEN_ADDRESS_LOADED = "PTOKEN_ADDRESS_LOADED";
export const PTOKEN_ADDRESS_ERROR = "PTOKEN_ADDRESS_ERROR";
export const PTOKEN_WITHDRAWAL_STARTED = "PTOKEN_WITHDRAWAL_STARTED";
export const PTOKEN_WITHDRAWAL_SUCCESS = "PTOKEN_WITHDRAWAL_SUCCESS";
export const PTOKEN_WITHDRAWAL_ERROR = "PTOKEN_WITHDRAWAL_ERROR";
/** Leaderboard **/
export const LEADERBOARD_LOADING = "LEADERBOARD_LOADING";
export const LEADERBOARD_LOADED = "LEADERBOARD_LOADED";
export const LEADERBOARD_LOAD_ERROR = "LEADERBOARD_LOAD_ERROR";
/** Risk free **/
export const RISK_FREE_LOADED = "RISK_FREE_LOADED";
export const RISK_FREE_CLEAR = "RISK_FREE_CLEAR";
export const RISK_FREE_UNSET_MODAL = "RISK_FREE_UNSET_MODAL";
/** Wallet **/
export const WALLET_EXCHANGE_RATES = "WALLET_EXCHANGE_RATES";
export const WALLET_EXCHANGE_RATES_FIAT = "WALLET_EXCHANGE_RATES_FIAT";
export const WALLET_EXCHANGE_RATES_CRYPTO = "WALLET_EXCHANGE_RATES_CRYPTO";
export const WALLET_SET_ACTIVE_CURRENCY = "WALLET_SET_ACTIVE_CURRENCY";
export const WALLET_SET_ACTIVE_TAB = "WALLET_SET_ACTIVE_TAB";
export const WALLET_BALANCES_LOADING = "WALLET_BALANCES_LOADING";
export const WALLET_BALANCES_LOADED = "WALLET_BALANCES_LOADED";
export const WALLET_CASINO_BALANCES_LOADED = "WALLET_CASINO_BALANCES_LOADED";
export const WALLET_LOCKED_BALANCES_LOADED = "WALLET_LOCKED_BALANCES_LOADED";
export const WALLET_BALANCES_LOAD_ERROR = "WALLET_BALANCES_LOAD_ERROR";
export const WALLET_BALANCES_CLEAR = "WALLET_BALANCES_CLEAR";
/** Fiat view **/
export const SET_FIAT_VIEW = "SET_FIAT_VIEW";
export const SET_ODDS_FORMAT = "SET_ODDS_FORMAT";
export const SET_FIAT_CURRENCY = "SET_FIAT_CURRENCY";
export const SET_EVENT_GROUP_OPEN_STATE = "SET_EVENT_GROUP_OPEN_STATE";
/** Bet **/
export const BET_LOAD = "BET_LOAD";
export const BET_ADD = "BET_ADD";
export const BET_REMOVE = "BET_REMOVE";
export const BET_REPLACE = "BET_REPLACE";
export const BET_AMOUNT_SET = "BET_AMOUNT_SET";
export const BET_WIN_AMOUNT_SET = "BET_WIN_AMOUNT_SET";
export const BET_CANCEL = "BET_CANCEL";
export const BET_PLACING = "BET_PLACING";
export const BET_PLACED = "BET_PLACED";
export const BET_CLEAR_PLACED = "BET_CLEAR_PLACED";
export const BET_ERROR = "BET_ERROR";
export const BET_ACCEPT_CHANGES = "BET_ACCEPT_CHANGES";
export const BET_OPTION_MODIFY = "BET_OPTION_MODIFY";
export const BET_PROMO_NO_RISK = "BET_PROMO_NO_RISK";
export const BET_CLEAR_ERROR = "BET_CLEAR_ERROR";
export const BET_LIMITS = "BET_LIMITS";
/** Bets **/
export const BETS_LOADING = "BETS_LOADING";
export const BETS_LOADED = "BETS_LOADED";
export const BETS_LOAD_ERROR = "BETS_LOAD_ERROR";
export const BETS_CLEAR = "BETS_CLEAR";
export const BETS_COMPLETE_BET = "BETS_COMPLETE_BET";
export const BETS_PROGRESS = "BETS_PROGRESS";
export const BETS_REMOVE_EVENT = "BETS_REMOVE_EVENT";
/** User **/
export const USER_SETTINGS_LOADING = "USER_SETTINGS_LOADING";
export const USER_SETTINGS_LOADED = "USER_SETTINGS_LOADED";
export const USER_SETTINGS_ERROR = "USER_SETTINGS_ERROR";
export const USER_SETTINGS_SAVING = "USER_SETTINGS_SAVING";
export const USER_SETTINGS_SAVED = "USER_SETTINGS_SAVED";
export const USER_SETTINGS_CLEAR = "USER_SETTINGS_CLEAR";

export const USER_TFA_SETTINGS_LOADING = "USER_TFA_SETTINGS_LOADING";
export const USER_TFA_SETTINGS_LOADED = "USER_TFA_SETTINGS_LOADED";
export const USER_TFA_SETTINGS_ERROR = "USER_TFA_SETTINGS_ERROR";
export const USER_TFA_SETTINGS_CLEAR = "USER_TFA_SETTINGS_CLEAR";
export const USER_TFA_SETTINGS_ENABLE_LOADING = "USER_TFA_SETTINGS_ENABLE_LOADING";
export const USER_TFA_SETTINGS_ENABLE_LOADED = "USER_TFA_SETTINGS_ENABLE_LOADED";
export const USER_TFA_SETTINGS_ENABLE_ERROR = "USER_TFA_SETTINGS_ENABLE_ERROR";
export const USER_TFA_SETTINGS_RESET_LOADING = "USER_TFA_SETTINGS_RESET_LOADING";
export const USER_TFA_SETTINGS_RESET_LOADED = "USER_TFA_SETTINGS_RESET_LOADED";
export const USER_TFA_SETTINGS_RESET_ERROR = "USER_TFA_SETTINGS_RESET_ERROR";

export const USER_TFA_SETTINGS_ACTIVATE_LOADING = "USER_TFA_SETTINGS_ACTIVATE_LOADING";
export const USER_TFA_SETTINGS_ACTIVATE_LOADED = "USER_TFA_SETTINGS_ACTIVATE_LOADED";
export const USER_TFA_SETTINGS_ACTIVATE_ERROR = "USER_TFA_SETTINGS_ACTIVATE_ERROR";
export const USER_TFA_SETTINGS_ACTIVATE_RESET = "USER_TFA_SETTINGS_ACTIVATE_RESET";

export const USER_TFA_SETTINGS_RECOVERY_LOADING = "USER_TFA_SETTINGS_RECOVERY_LOADING";
export const USER_TFA_SETTINGS_RECOVERY_LOADED = "USER_TFA_SETTINGS_RECOVERY_LOADED";
export const USER_TFA_SETTINGS_RECOVERY_ERROR = "USER_TFA_SETTINGS_RECOVERY_ERROR";
export const USER_TFA_SETTINGS_RECOVERY_RESET = "USER_TFA_SETTINGS_RECOVERY_RESET";

export const USER_TFA_SETTINGS_DEACTIVATE_LOADING =
  "USER_TFA_SETTINGS_DEACTIVATE_LOADING";
export const USER_TFA_SETTINGS_DEACTIVATE_LOADED = "USER_TFA_SETTINGS_DEACTIVATE_LOADED";
export const USER_TFA_SETTINGS_DEACTIVATE_ERROR = "USER_TFA_SETTINGS_DEACTIVATE_ERROR";
export const USER_TFA_SETTINGS_DEACTIVATE_RESET = "USER_TFA_SETTINGS_DEACTIVATE_RESET";

export const USER_EXCLUDE_SAVING = "USER_EXCLUDE_SAVING";
export const USER_EXCLUDE_SAVED = "USER_EXCLUDE_SAVED";
export const USER_EXCLUDE_ERROR = "USER_EXCLUDE_ERROR";
export const USER_EXCLUDE_CLEAR = "USER_EXCLUDE_CLEAR";

export const USER_REACTIVATE_TOGGLE = "USER_REACTIVATE_TOGGLE";
export const USER_REACTIVATE_SAVING = "USER_REACTIVATE_SAVING";
export const USER_REACTIVATE_SAVED = "USER_REACTIVATE_SAVED";
export const USER_REACTIVATE_ERROR = "USER_REACTIVATE_ERROR";

export const USER_EMAIL_SAVING = "USER_EMAIL_SAVE";
export const USER_EMAIL_SAVED = "USER_EMAIL_SAVED";
export const USER_EMAIL_ERROR = "USER_EMAIL_ERROR";
export const USER_EMAIL_TOGGLE = "USER_EMAIL_TOGGLE";

export const USER_EMAIL_CONFIRMATION_SENDING = "USER_EMAIL_CONFIRMATION_SENDING";
export const USER_EMAIL_CONFIRMATION_SENT = "USER_EMAIL_CONFIRMATION_SENT";
export const USER_EMAIL_CONFIRMATION_ERROR = "USER_EMAIL_CONFIRMATION_ERROR";

export const USER_SUBSCRIBE_SAVING = "USER_SUBSCRIBE_SAVING";
export const USER_SUBSCRIBE_SAVED = "USER_SUBSCRIBE_SAVED";
export const USER_SUBSCRIBE_ERROR = "USER_SUBSCRIBE_ERROR";

export const USER_ONSITE_SAVING = "USER_ONSITE_SAVING";
export const USER_ONSITE_SAVED = "USER_ONSITE_SAVED";
export const USER_ONSITE_ERROR = "USER_ONSITE_ERROR";

export const USER_RESET_NOTIFICATIONS_SETTINGS = "USER_RESET_NOTIFICATIONS_SETTINGS";

/** Unsubscribe */
export const UNSUBSCRIBE_INIT = "UNSUBSCRIBE_INIT";
export const UNSUBSCRIBE_LOADING = "UNSUBSCRIBE_LOADING";
export const UNSUBSCRIBE_ERROR = "UNSUBSCRIBE_ERROR";
export const UNSUBSCRIBE_SUCCESS = "UNSUBSCRIBE_SUCCESS";
export const UNSUBSCRIBE_CLOSE = "UNSUBSCRIBE_CLOSE";

/** TFA Confirm */
export const TFA_CONFIRM_LOCK = "TFA_CONFIRM_LOCK";
export const TFA_CONFIRM_UNLOCK = "TFA_CONFIRM_UNLOCK";
export const TFA_CONFIRM_TOGGLE_RECOVERY = "TFA_CONFIRM_TOGGLE_RECOVERY";
export const TFA_CONFIRM_LOADING = "TFA_CONFIRM_LOADING";
export const TFA_CONFIRM_CHANGE_CODE = "TFA_CONFIRM_CHANGE_CODE";
export const TFA_CONFIRM_ERROR = "TFA_CONFIRM_ERROR";

/** Homepage */
export const HOMEPAGE_CHANGE_SPORT = "HOMEPAGE_CHANGE_SPORT";
export const HOMEPAGE_RESET_SPORT = "HOMEPAGE_RESET_SPORT";
export const HOMEPAGE_SPORT_LOAD = "HOMEPAGE_SPORT_LOAD";
export const HOMEPAGE_SPORT_ERROR = "HOMEPAGE_SPORT_ERROR";
export const HOMEPAGE_SPORT_LOADED = "HOMEPAGE_SPORT_LOADED";

/** Change password */
export const CHANGE_PASSWORD_LOAD = "CHANGE_PASSWORD_LOAD";
export const CHANGE_PASSWORD_LOADED = "CHANGE_PASSWORD_LOADED";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
export const CHANGE_PASSWORD_RESET = "CHANGE_PASSWORD_RESET";

/** Notifications */
export const NOTIFICATIONS_RECENT_LOADING = "NOTIFICATIONS_RECENT_LOADING";
export const NOTIFICATIONS_RECENT_LOADED = "NOTIFICATIONS_RECENT_LOADED";
export const NOTIFICATIONS_RECENT_ERROR = "NOTIFICATIONS_RECENT_ERROR";
export const NOTIFICATIONS_RECENT_HIDE = "NOTIFICATIONS_RECENT_HIDE";
export const NOTIFICATIONS_RECENT_CLEAR = "NOTIFICATIONS_RECENT_CLEAR";
export const NOTIFICATIONS_ALL_LOADING = "NOTIFICATIONS_ALL_LOADING";
export const NOTIFICATIONS_ALL_ERROR = "NOTIFICATIONS_ALL_ERROR";
export const NOTIFICATIONS_ALL_LOADED = "NOTIFICATIONS_ALL_LOADED";
export const NOTIFICATIONS_UNREAD_LOADING = "NOTIFICATIONS_UNREAD_LOADING";
export const NOTIFICATIONS_UNREAD_ERROR = "NOTIFICATIONS_UNREAD_ERROR";
export const NOTIFICATIONS_UNREAD_LOADED = "NOTIFICATIONS_UNREAD_LOADED";
export const NOTIFICATIONS_READ_ALL_LOADING = "NOTIFICATIONS_MARK_ALL_READ_LOADING";
export const NOTIFICATIONS_READ_ALL_LOADED = "NOTIFICATIONS_MARK_ALL_READ_LOADED";
export const NOTIFICATIONS_READ = "NOTIFICATIONS_READ";

export const SET_HIDE_ZERO = "SET_HIDE_ZERO";
export const SET_SHOW_BONUS = "SET_SHOW_BONUS";

export const WELCOME_BONUS_LOADING = "WELCOME_BONUS_LOADING";
export const WELCOME_BONUS_LOADED = "WELCOME_BONUS_LOADED";
export const WELCOME_BONUS_ERROR = "WELCOME_BONUS_ERROR";
export const WELCOME_BONUS_CLEAR = "WELCOME_BONUS_CLEAR";

/** Bonus */
export const BONUS_REGISTER_LOADING = "BONUS_REGISTER_LOADING";
export const BONUS_REGISTER_LOADED = "BONUS_REGISTER_LOADED";
export const BONUS_REGISTER_ERROR = "BONUS_REGISTER_ERROR";
export const BONUS_REGISTER_REFRESH = "BONUS_REGISTER_REFRESH";
export const BONUS_PACKS_LOADING = "BONUS_PACKS_LOADING";
export const BONUS_PACKS_LOADED = "BONUS_PACKS_LOADED";
export const BONUS_PACKS_CLEAR = "BONUS_PACKS_CLEAR";
export const BONUS_PACKS_ERROR = "BONUS_PACKS_ERROR";
export const BONUS_ACTIVE_LOADING = "BONUS_ACTIVE_LOADING";
export const BONUS_ACTIVE_LOADED = "BONUS_ACTIVE_LOADED";
export const BONUS_ACTIVE_ERROR = "BONUS_ACTIVE_ERROR";
export const BONUS_LIST_LOADING = "BONUS_LIST_LOADING";
export const BONUS_LIST_LOADED = "BONUS_LIST_LOADED";
export const BONUS_LIST_ERROR = "BONUS_LIST_ERROR";
export const BONUS_CANCEL_LOADING = "BONUS_CANCEL_LOADING";
export const BONUS_CANCEL_LOADED = "BONUS_CANCEL_LOADED";
export const BONUS_CANCEL_ERROR = "BONUS_CANCEL_ERROR";
export const BONUS_ACCEPT_REFUSE_LOADING = "BONUS_ACCEPT_REFUSE_LOADING";
export const BONUS_ACCEPT_REFUSE_LOADED = "BONUS_ACCEPT_REFUSE_LOADED";
export const BONUS_ACCEPT_REFUSE_ERROR = "BONUS_ACCEPT_REFUSE_ERROR";
export const WALLET_ADDRESSES_LOADING = "WALLET_ADDRESSES_LOADING";
export const WALLET_ADDRESSES_LOADED = "WALLET_ADDRESSES_LOADED";
export const WALLET_ADDRESSES_LOAD_ERROR = "WALLET_ADDRESSES_LOAD_ERROR";
export const BONUS_ERROR_CLEAR = "BONUS_ERROR_CLEAR";
export const BONUS_GET_AND_ACCEPT_LOADING = "BONUS_GET_AND_ACCEPT_LOADING";
export const BONUS_GET_AND_ACCEPT_SUCCESS = "BONUS_GET_AND_ACCEPT_SUCCESS";
export const BONUS_GET_AND_ACCEPT_ERROR = "BONUS_GET_AND_ACCEPT_ERROR";
export const BONUS_GET_AND_ACCEPT_RESET = "BONUS_GET_AND_ACCEPT_RESET";

export const USER_STATS_LOADING = "USER_STATS_LOADING";
export const USER_STATS_LOADED = "USER_STATS_LOADED";
export const USER_STATS_ERROR = "USER_STATS_ERROR";

/* Experiment */
export const EXPERIMENTS_SET = "EXPERIMENTS_SET";
/* Experiment */
export const ZECHAT_LOADED = "ZECHAT_LOADED";
export const ZECHAT_LOADING = "ZECHAT_LOADING";
export const ZECHAT_ERROR = "ZECHAT_ERROR";
