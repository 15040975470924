import { createSelector } from "reselect";

export const getBonusState = (state) => state.bonus;
export const getBonusCode = createSelector(getBonusState, (bonus) => bonus.bonusCode);
export const getBonusPacks = createSelector(getBonusState, (bonus) => bonus.bonusPacks);
export const getActiveBonus = createSelector(getBonusState, (bonus) => bonus.activeBonus);
export const getBonusList = createSelector(getBonusState, (bonus) => bonus.bonusList);
export const getBonusCancel = createSelector(getBonusState, (bonus) => bonus.bonusCancel);
export const getAndAcceptBonusSelector = createSelector(
  getBonusState,
  (bonus) => bonus.bonusGetAndAccept
);
