import { combineReducers } from "redux";

import activeBonusReducer from "./activeBonus";
import bonusCancelReducer from "./bonusCancel";
import bonusCodeReducer from "./bonusCode";
import bonusGetAndAcceptReducer from "./bonusGetAndAccept.ts";
import bonusListReducer from "./bonusList";
import bonusPacksReducer from "./bonusPacks";

export default combineReducers({
  bonusCode: bonusCodeReducer,
  bonusPacks: bonusPacksReducer,
  bonusGetAndAccept: bonusGetAndAcceptReducer,
  activeBonus: activeBonusReducer,
  bonusList: bonusListReducer,
  bonusCancel: bonusCancelReducer,
});
