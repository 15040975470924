import {
  BONUS_GET_AND_ACCEPT_ERROR,
  BONUS_GET_AND_ACCEPT_LOADING,
  BONUS_GET_AND_ACCEPT_RESET,
  BONUS_GET_AND_ACCEPT_SUCCESS,
} from "actions/actionTypes";

export enum BonusGetAndAcceptStatuses {
  IDLE = "IDLE",
  LOADING = "LOADING",
  ALREADY_HAS_BONUS = "ALREADY_HAS_BONUS",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}

const initialState = {
  status: BonusGetAndAcceptStatuses.IDLE,
};

const bonusGetAndAcceptReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case BONUS_GET_AND_ACCEPT_LOADING:
      return { status: BonusGetAndAcceptStatuses.LOADING };
    case BONUS_GET_AND_ACCEPT_SUCCESS:
      return { status: action.payload.status };
    case BONUS_GET_AND_ACCEPT_ERROR:
      return { status: BonusGetAndAcceptStatuses.ERROR };
    case BONUS_GET_AND_ACCEPT_RESET:
      return initialState;
    default:
      return state;
  }
};

export default bonusGetAndAcceptReducer;
